import {
  createContext, useCallback, useContext, useMemo, useState
} from 'react';

import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import PropTypes from 'prop-types';

import useMemoObject from '@zenbusiness/application-commons-hooks/useMemoObject';
import Button from '@zenbusiness/zui/Button';
import Menu from '@zenbusiness/zui/Menu';
import MenuItem from '@zenbusiness/zui/MenuItem';
import { superstyled } from '@zenbusiness/zui/styles';

const TopNavMenuContext = createContext();
TopNavMenuContext.displayName = 'TopNavMenuContext';

const NavMenuRoot = superstyled({
  tag: Menu,
  propsAdded: {
    elevation: 2,
    anchorOrigin: {
      vertical: 'bottom',
      horizontal: 'left'
    },
    transformOrigin: {
      vertical: 'top',
      horizontal: 'left'
    }
  }
});

const NavButton = superstyled({
  tag: Button,
  propsAdded: {
    variant: 'text',
    size: 'small'
  },
  styles: {
    fontSize: '1rem',
    border: '1px solid transparent',
    whiteSpace: 'nowrap'
  }
});

function NavMenu({ label, children }) {
  // generate a random id to use to associate the elements
  const id = useMemo(() => `id-${Math.round(Math.random() * 10 ** 6).toString(16)}`, []);

  const [anchorEl, setAnchorEl] = useState(null);
  const open = !!(anchorEl);

  const onButtonClick = useCallback((event) => {
    setAnchorEl(event.currentTarget);
  }, []);

  const onMenuClose = useCallback(() => {
    setAnchorEl(null);
  }, []);

  const context = useMemoObject({
    open,
    onButtonClick,
    onMenuClose
  });

  return (
    <div>
      <NavButton
        id={`${id}-button`}
        aria-controls={open ? `${id}-menu` : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        // variant={open ? 'text' : 'link'}
        onClick={onButtonClick}
        endIcon={<KeyboardArrowDownIcon />}
        sx={{ mx: 1 }}
      >
        {label}
      </NavButton>
      <NavMenuRoot
        id={`${id}-menu`}
        MenuListProps={{
          'aria-labelledby': `${id}-button`
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={onMenuClose}
      >
        <TopNavMenuContext.Provider value={context}>
          {children}
        </TopNavMenuContext.Provider>
      </NavMenuRoot>
    </div>
  );
}
NavMenu.propTypes = {
  label: PropTypes.node.isRequired
};

export default function NavItem({
  href, label, children
}) {
  const dd = useContext(TopNavMenuContext);

  if (dd) {
    // this is an item inside an item
    return <MenuItem component="a" href={href} onClick={dd.onClose}>{label}</MenuItem>;
  }

  if (href) {
    return (
      <NavButton href={href}>{label}</NavButton>
    );
  }

  return (
    <NavMenu label={label}>{children}</NavMenu>
  );
}
NavItem.propTypes = {
  href: PropTypes.string,
  label: PropTypes.node.isRequired
};
