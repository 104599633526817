import { useNavigate } from 'react-router-dom';

import { useIdentity } from '@zenbusiness/application-commons-identity';
import Box from '@zenbusiness/zui/Box';
import Button from '@zenbusiness/zui/Button';
import Heading from '@zenbusiness/zui/Heading';
import SharedAsset from '@zenbusiness/zui/SharedAsset';

export default function Landing() {
  const navigate = useNavigate();
  const { authenticated, identified, firstName } = useIdentity();
  return (
    <Box display="flex" flexDirection="column" justifyContent="center" alignItems="center" minHeight="20vh" m={5}>
      <Heading>{identified ? `Welcome ${firstName}!` : 'Welcome to ZenGarden!'}</Heading>
      <SharedAsset alt="Celebrating" src="/illustration/dog-celebrate-right-256.svg" />
      {!authenticated && (
      <Box justifyContent="center" alignItems="center" m={1}>
        <Button variant="outlined" onClick={() => (navigate('/login'))}>Login With Okta</Button>
      </Box>
      )}
    </Box>
  );
}
