export const AUTH_ERROR_CODES = ['UNAUTHENTICATED', 'FORBIDDEN', 'AUTH_EXCEPTION'];

export default function globalGraphQLErrorHandler({ result }) {
  // Ignore account read errors here, so we can handle in component
  const errors = result?.error?.graphQLErrors?.map?.(({
    message,
    extensions: {
      code,
      errorCode
    } = {}
  } = {}) => ({
    code: code || errorCode,
    message
  })) || [];

  const authError = errors && errors.find(
    ({ code }) => AUTH_ERROR_CODES.includes(code)
  );

  // If someone provides a bad UUID, force re-login
  const invalidBusinessEntityUuidError = errors && errors.find(
    ({
      code,
      message
    }) => (
      (code === 'FORBIDDEN' && message === 'Permission failure')
      || (code === 'BAD_USER_INPUT' && message.includes('UUID cannot represent non-UUID'))
    )
  );
    /* if the user is trying to access a resource that returns 403 take them
        to the "home" page of the dashboard.
    */
  if (authError || invalidBusinessEntityUuidError) {
    const { pathname } = window.location;
    if (!pathname || !pathname.startsWith('/d/logout')) {
      window.location.assign(`/d/logout?redirectTo=${pathname}`);
    }
  }
}
