import { RouterProvider, createBrowserRouter } from 'react-router-dom';

import { EnvProvider } from '@zenbusiness/application-commons-env';
import PageError from '@zenbusiness/zui/PageError';

import Authenticated from '#client/App/Authenticated';
import PostRouting from '#client/App/PostRouting';
import { DEFAULT_SEGMENT } from '#client/build-env';
import Template from '#client/components/Template';
import * as ROUTES from '#client/constants/routes';
import * as Pages from '#client/pages';

import Federated from './Federated';

const router = createBrowserRouter([
  {
    /**
     * Routes which do not require authentication at page load
     */
    element: <PostRouting />,
    children: [
      { path: ROUTES.login, element: <Pages.Login /> },
      { path: ROUTES.logout, element: <Pages.Logout /> },
      { path: ROUTES.callback, element: <Pages.Callback /> }
    ]
  },
  {
    /**
     * Routes which can support authentication but do not require it
     */
    element: (
      <Template>
        <Authenticated optional>
          <PostRouting />
        </Authenticated>
      </Template>
    ),
    children: [
      { index: true, element: <Pages.Landing /> }
    ]
  },
  {
    /**
     * Routes which can support authentication but do not require it
     */
    path: DEFAULT_SEGMENT,
    element: (
      <Template>
        <Authenticated optional>
          <PostRouting />
        </Authenticated>
      </Template>
    ),
    children: [
      { index: true, element: <Pages.Landing /> }
    ]
  },
  {
    /*
      Federated Module Test Routes
      If your MFE has federated entry points, you can recreate those ingress paths here
      in order to test those federated components.
    */
    path: `/${DEFAULT_SEGMENT}/${ROUTES.landing}/*`,
    element: (
      <Authenticated optional>
        <PostRouting />
      </Authenticated>
    ),
    children: [
      {
        // Example for testing a federated named export
        path: 'component',
        element: <Federated exportName="BusinessEntity" />
      },
      {
        // Example for testing a federated default export, with its own routing
        path: '*',
        element: <Federated />
      }
    ]
  },
  {
    path: '*',
    element: <PageError>The requested route could not be found.</PageError>
  }
]);

export function DirectRoutes() {
  return (
    // ROOT_SEGMENT is used to identify where the router is mounted at.
    // Since this is a direct entry router, the root is the MFE's DEFAULT_SEGMENT
    <EnvProvider env={{ ROOT_SEGMENT: DEFAULT_SEGMENT }}>
      <RouterProvider router={router} />
    </EnvProvider>
  );
}
