import createCache from '@emotion/cache';
import { CacheProvider } from '@emotion/react';

import { AuthenticationProvider } from '@zenbusiness/application-commons-authentication';
import { useEnv } from '@zenbusiness/application-commons-env';
import { GraphQLProvider } from '@zenbusiness/application-commons-graphql';
import { IdentityProvider } from '@zenbusiness/application-commons-identity';
import { LoadingZone, SuspenseZone } from '@zenbusiness/application-commons-loading';
import { LoggingProvider, ErrorBoundary } from '@zenbusiness/application-commons-logging';
import { WebView } from '@zenbusiness/application-commons-webview';
import PageError from '@zenbusiness/zui/PageError';
import PageLoading from '@zenbusiness/zui/PageLoading';
import { ZuiThemeProvider } from '@zenbusiness/zui/styles';

import { NAME, RELEASE_VERSION } from '#client/build-env';

import globalGraphQLErrorHandler from './GraphQLErrorHandler';

const emotionCache = createCache({
  key: NAME
});

// eslint-disable-next-line react/prop-types
export default function Providers({ children, federated }) {
  const {
    AUTH0_AUDIENCE,
    AUTH0_CLIENTID,
    AUTH0_CONNECTION,
    AUTH0_DOMAIN,
    GRAPHQL_ROUTE,
    AUTH_PATHS
  } = useEnv('head-first'); // use MFE values
  // const {
  // } = useEnv('root-first'); // use parent values

  return (
    <LoadingZone fallback={<PageLoading />} loadId="App/Providers">
      <LoggingProvider
        tag-frontend={NAME}
        tag-frontend-release={`${NAME}-${(RELEASE_VERSION || 'unset')}`}
        tag-isWebView={String(WebView?.isWebView)}
      >
        <ErrorBoundary fallback={<PageError />} id={`${NAME}/App/Providers`}>
          <CacheProvider value={emotionCache}>
            <ZuiThemeProvider baseline={!federated} scopedBaseline={federated}>
              <AuthenticationProvider
                clientID={AUTH0_CLIENTID}
                domain={AUTH0_DOMAIN}
                audience={AUTH0_AUDIENCE}
                connection={AUTH0_CONNECTION}
                {...AUTH_PATHS}
                debug
              >
                <GraphQLProvider url={GRAPHQL_ROUTE} onError={globalGraphQLErrorHandler}>
                  <IdentityProvider>
                    <SuspenseZone loadId={`${NAME}/App/Providers#Intl`}>
                      {children}
                    </SuspenseZone>
                  </IdentityProvider>
                </GraphQLProvider>
              </AuthenticationProvider>
            </ZuiThemeProvider>
          </CacheProvider>
        </ErrorBoundary>
      </LoggingProvider>
    </LoadingZone>
  );
}
