/* eslint-disable react/prop-types */
import camelCase from 'lodash/camelCase';
import upperFirst from 'lodash/upperFirst';

import { FederatedModule } from '@zenbusiness/application-commons-federation';

import { NAME, DEFAULT_SEGMENT } from '#client/build-env';

const name = upperFirst(camelCase(NAME));

export default function Federated({
  src = `${DEFAULT_SEGMENT}/static/js/remoteEntry.js`,
  globalScope = name,
  module = 'default',
  ...rest
}) {
  return <FederatedModule {...rest} src={src} module={module} globalScope={globalScope} />;
}
