import { Outlet } from 'react-router-dom';

import { LoadingZone, SuspenseZone } from '@zenbusiness/application-commons-loading';
import { ErrorBoundary } from '@zenbusiness/application-commons-logging';
import PageError from '@zenbusiness/zui/PageError';

export default function PostRouting({ children }) {
  return (
    <ErrorBoundary fallback={<PageError />} id="src/App/PostRouting">
      <LoadingZone
        bubble
        loadId="PostRouting"
        timeout={10000}
      >
        <SuspenseZone loadId="PostRouting Suspense">
          {children || <Outlet />}
        </SuspenseZone>
      </LoadingZone>
    </ErrorBoundary>
  );
}
