/**
 * This file serves as the entry point for your application when directly hitting
 * its path prefix (eg, `/mfe/x/foo`). This file contains the initialization code for react,
 * the environment setup for using the application standalone, the app wrapper component
 * which provides all the providers that are common for all entry points (local and federated),
 * and then the local router configuration that includes the login/logout pages for local
 * development.
 *
 * If your application is exclusifely a federated micro-frontend, then we suggest
 * you use this file for testing your federated endpoints.
 */

// core-js must be at the top of every webpack entry
// point for polyfills to be present in the build
import 'core-js/stable';

import { createElement, StrictMode } from 'react';

import { createRoot } from 'react-dom/client';

import { EnvProvider } from '@zenbusiness/application-commons-env';

import AppWrapper from '#client/App/Providers';
import BUILD_ENV from '#client/build-env';

import { DirectRoutes } from './DirectRoutes';

const ENV = {
  ...BUILD_ENV,
  ...window.ENV
};

function StrictishMode({ children }) {
  if (ENV.USE_STRICTMODE) return <StrictMode>{children}</StrictMode>;
  return children;
}

function App() {
  return (
    <StrictishMode>
      <EnvProvider env={ENV}>
        <AppWrapper>
          <DirectRoutes />
        </AppWrapper>
      </EnvProvider>
    </StrictishMode>
  );
}

const element = document.getElementById('root');
const root = createRoot(element);
root.render(createElement(App));

/* webpack-strip-block:removed */
