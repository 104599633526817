/* eslint-disable react/prop-types */
import { useEffect } from 'react';

import { Outlet, useLocation } from 'react-router-dom';

import { useAuthentication } from '@zenbusiness/application-commons-authentication';
import { ErrorBoundary } from '@zenbusiness/application-commons-logging';
import Empty from '@zenbusiness/zui/Empty';
import PageError from '@zenbusiness/zui/PageError';

import { NAME } from '#client/build-env';

/**
 * Router outlet which validates authentication and sets up the business entity provider.
 * This must be performed within an outlet, because BusinessEntityProvider needs access to react-router,
 * which does not exist at the Providers.js layer.
 * @param {object} props
 * @param {boolean} [props.optional] If false or absent, an unauthenticated user will be forced to login
 * @param {*} props.children
 * @component
 */
export default function Authenticated({ optional, children }) {
  const { authenticated, login } = useAuthentication();
  const { pathname, search } = useLocation();

  useEffect(() => {
    if (optional || authenticated) {
      return;
    }
    login(`${pathname}${search}`);
  }, [pathname, search, optional, authenticated, login]);

  if (!optional && !authenticated) {
    return <Empty unauthenticated />;
  }

  return (
    <>
      {/* Route/Template Error Boundary */}
      <ErrorBoundary fallback={<PageError />} id={`${NAME}/App/Authenticated`}>
        {children || <Outlet />}
      </ErrorBoundary>
    </>
  );
}
